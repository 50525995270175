import React from 'react';

import PageNotFound from '~/components/views/PageNotFound';
import AppContainer from '~/containers/AppContainer/AppContainer';

const Custom404: React.FC = () => {
    return (
        <AppContainer>
            <PageNotFound />
        </AppContainer>
    );
};

export default Custom404;
