import React from 'react';
import Image from 'next/legacy/image';
import { createGlobalStyle } from 'styled-components';

import notFoundIcon from '~/assets/img/navigation/404_bottle.svg';

import StyledPageNotFound from './PageNotFound.styled';

const GlobalStyle = createGlobalStyle` 
footer{
display: none !important;
}`;

const PageNotFound = () => {
    return (
        <StyledPageNotFound>
            <GlobalStyle />
            <Image src={notFoundIcon} alt={'page not found icon'} width={90} height={126} />
            <h1 data-testid={'title'}>Page Not Found</h1>
            <h6 data-testid={'subtitle'}>The page is missing or you assembled the link incorrectly</h6>
        </StyledPageNotFound>
    );
};
export default PageNotFound;
